<template>
  <div class="pb-20">

    <div class="grid grid-cols-1 md:grid-cols-2 mb-10">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Declined Users</h2>
        <router-link :to="{ name: 'users-new'}" class="text-sm text-gray-700">
          + Add new user
        </router-link>
      </div>
    </div>

    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajax-pagination="true"
        :columns="users.columns"
        :query="users.query"
        :loading="users.loading"
        :url="users.url"
        :on-click="click"
        sort="created_at"
        order="asc"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="users.query"
            class="mb-5"
            placeholder="Search for user's name, status, etc."
            @submit="reloadTable"
          />
        </template>
      </datatable>
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />

  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    data() {
      return {
        currentFilter: null,
        users: this.$options.resource([], {
          url: `${this.$baseurl}/admin/personal/users/status/declined`,
          query: '',
          selected: null,
          columns: [
            {
              name: 'id',
              th: 'User ID',
            },
            {
              name: 'name',
              th: 'Name',
              render: (user) => user.name + ' ' + user.last_name
            },
            {
              name: 'email',
              th: 'Email Address',
            },
            {
              name: 'progress',
              th: 'Progress',
              render: (user) => {
                return this.progressIcons(user);
              },
              sortable: false
            },
            {
              name: 'status',
              th: 'Status',
              render: (user, status) => {
                return this.statusBadge(status, user);
              },
              sortable: false
            },
            {
              name: 'admins',
              th: 'Assigned To',
              render: (user) => {
                return this.assignedAdmin(user)
              },
              sortable: false
            },
            {
              name: 'declined_at',
              th: 'Date Declined',
              render: ({ credit_application }) => moment(credit_application?.attended_at).format('MMM Do YYYY')
            },
            {
              name: 'created_at',
              th: 'Date Registered',
              render: (user, created_at) => moment(created_at).format('MMM Do YYYY')
            }
          ],
        }),
      }
    },
    methods: {
      click(user) {
        this.users.selected = user;
        this.$refs.userModal.open();
      },
      reloadTable() {
        this.$refs.table.loadAjaxData()
      },
      toggleFilter(filter) {
        if (this.currentFilter === filter) {
          this.currentFilter = null
        }else {
          this.currentFilter = filter
        }

        this.$refs.table.clickedFilter(this.currentFilter)
      },
      userDeleted() {
        this.reloadTable();
      },
      userModalClosed() {
        this.users.selected = null;
      }
    }
  }
</script>
